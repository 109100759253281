<template>
  <generic-content
      :isLinkable="false"
      :isEditable="isEditable"
      :isPayable="isPayable"
      :isDeletable="isDeletable"
      :isDownloadable="isDownloadable"
      :isViewable="isViewable"
      @click="$emit('click')"
      @edit="$emit('edit')"
      @download="$emit('download')"
      @delete="$emit('delete')"
  >
    <template #iconContent>
      <b-avatar
          size="32"
          variant="light-primary"
          class="my-auto"
          :text="initials(chargeTaxe.supplier._display)"
      />
    </template>

    <template #bodyContent>
      <h6 class="transaction-title text-truncate">
        {{ chargeTaxe.supplier._display }}
      </h6>
      <div class="d-flex justify-content-between text-truncate">
        <small>
          <i>{{ chargeTaxe.month|moment('MMMM YYYY')|capitalize }}</i>
        </small>
        <small>
          {{ currency(chargeTaxe.preTaxAmount) }}
        </small>
      </div>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { initials } from '../../../utils/utils'
import { currency } from '../../../utils/filter'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
    chargeTaxe: {
      type: Object,
      default: () => {}
    },
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      initials,
      currency,

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>