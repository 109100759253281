<template>
  <div>
    <search-filter
        :items="socialSecurityContributions"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        :allowAddByUpload="true"
        @addItem="addSocialSecurityContribution"
        @addItemByUpload="socialSecurityContributionsByUploadModalShow=true"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT</span>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-charge-taxe
            :charge-taxe="item"
            @click="selectSocialSecurityContribution(item)"
            @edit="selectSocialSecurityContribution(item)"
            @download="downloadSocialSecurityContribution(item)"
            @delete="deleteSocialSecurityContributionAlert(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Edit-->
          <button-edit
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              @click.native.stop="selectSocialSecurityContribution(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadSocialSecurityContribution(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


          <!--          Delete-->
          <button-delete
              @click.native.stop="deleteSocialSecurityContributionAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>

    </search-filter>


    <!-- modal new social security contributions-->
    <modal-social-security-contributions
        :socialSecurityContributions.sync="currentSocialSecurityContributions"
        :title="socialSecurityContributionModalTitle"
        :isOpen.sync="socialSecurityContributionModalShow"
        @submitValidated="submitValidatedSocialSecurityContributionLocal(currentSocialSecurityContributions)"
    />

    <!-- modal social security contributions by upload-->
    <modal-upload
        :singleFile.sync="newSocialSecurityContributionsFile"
        :documentType="'socialSecurityContribution'"
        :title="capitalize($tc('socialSecurityContribution',2))"
        :isOpen.sync="socialSecurityContributionsByUploadModalShow"

    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinSocialSecurityContributions } from './mixinSocialSecurityContributions'

export default {
  components: {},
  mixins: [mixinSocialSecurityContributions],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>